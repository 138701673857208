import Avatar from '@/components/Avatar'
import Button from '@/components/Button'
import IcoMoon from '@/components/IcoMoon'
import { Stack } from '@/components/Layout'
import MenuLinkExternal from '@/components/MenuLinks'
import { useAuth } from '@/context/auth'
import { useFeatureToggle } from '@/context/featureToggles'
import { getConfig } from '@/lib/config'
import AuthMenu from '@/widgets/AuthMenu'
import LanguagePicker from '@/widgets/LanguagePicker'
import UserCard from '@/widgets/UserCard'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'
import * as styles from './style'

interface ExternalLink {
  url: string
  label: string
  openInNewTab: boolean
}

const { publicRuntimeConfig } = getConfig()
const BASE_URL = publicRuntimeConfig.website.url

export interface Props {
  menuVisible: boolean
  close: () => void
}

const SliderMenu = ({ menuVisible, close }: Props): JSX.Element => {
  const externalLinks = ExternalLinks()
  const canSeeClassNotes = useFeatureToggle('eng_class_notes')
  const {
    state: { user },
    dispatch,
  } = useAuth()
  const isAuthenticated = useMemo(() => {
    if (user) {
      return true
    }
    return false
  }, [user])

  function ExternalLinks(): ExternalLink[] {
    const { t } = useTranslation('common')
    const externalLinks: ExternalLink[] = [
      { label: t('nav.mySchedule'), url: `${BASE_URL}/schedule/my-schedule`, openInNewTab: false },
      { label: t('menu.blog'), url: 'https://www.getsetup.com/', openInNewTab: true },
      { label: t('nav.help'), url: 'https://getsetup6054.zendesk.com/hc/en-us', openInNewTab: true },
    ]
    return externalLinks
  }

  return (
    <nav>
      <CSSTransition
        in={menuVisible}
        timeout={styles.transitionDelay}
        unmountOnExit
        classNames={`${styles.menuWrapper} sliderMenu`}
      >
        <Stack y>
          <span className={styles.closeMenu} onClick={close}>
            <IcoMoon icon="close" size={24} />
          </span>
          <span className={styles.languagePicker}>
            <LanguagePicker />
          </span>
          <div className="pb-lg">
            {canSeeClassNotes && (
              <UserCard
                avatar={<Avatar title={user?.fullName} size="sm" />}
                theme="primaryNext"
                title={user?.firstName}
                href="/notes"
                onClick={close}
              />
            )}
            {!isAuthenticated && (
              <img src="/images/logo-getsetup-light.svg" alt="GetSetUp logo" loading="lazy" width="120px" />
            )}
          </div>
          {externalLinks.map((externalLink, i) => (
            <div key={i} className="py-xxs">
              <MenuLinkExternal
                label={externalLink.label}
                url={externalLink.url}
                openInNewTab={externalLink.openInNewTab}
              />
            </div>
          ))}
          <div className="pt-lg">
            {!isAuthenticated && <AuthMenu />}
            {isAuthenticated && (
              <Button
                className={styles.logoutButton}
                size="sm"
                theme="secondary"
                label="Log out"
                onClick={() => dispatch({ type: 'LOGOUT' })}
              />
            )}
          </div>
        </Stack>
      </CSSTransition>
      <CSSTransition in={menuVisible} timeout={styles.transitionDelay} unmountOnExit classNames="sliderBackdrop">
        <div className={`${styles.backdrop} backDrop`}></div>
      </CSSTransition>
    </nav>
  )
}

export default SliderMenu
